/* metadata config container */
.metadata-config-container {
    position: relative;
}


.metadata-traits-edit-form .label-small {
    color: #666;
    margin-top: 24px;
    font-size: 12px;
}

/* overview button */
.metadata-config-container .metadata-overview-button {
    float: right;
    margin-top: 32px;
}

/* metadata traits edit */
.metadata-trait-edit-row {
    display: flex;
}

.metadata-trait-edit-row .metadata-trait-edit-values {
    margin-top: 24px;
    margin-bottom: 24px;
}


/* edit form step 2 */
.metadata-traits-edit-form .metadata-edit-input {
    margin-right: 12px;
}

.metadata-traits-edit-form .metadata-config-edit-row {
    position: relative;
    display: flex;
}

.metadata-traits-edit-form .metadata-trait-remove-button {
    padding: 0;
    margin: 32px 0 0 24px;
}

.metadata-traits-edit-form .metadata-trait-add-button {
    margin-top: 24px;
    margin-bottom: 24px;
}

.metadata-trait-edit-form-item {
    padding-top: 8px;
    padding-bottom: 32px;
    border-bottom: 1px solid #ccc;
}

/* step 3 */
.metadata-traits-edit-inner {
    display: flex;
    margin: 32px 0 24px 0;
}

.metadata-config-edit-row {
    display: flex;
    margin-bottom: 12px;
}

.metadata-config-edit-row-index {
    font-size: 20px;
    margin-top: 48px;
    margin-right: 48px;
    margin-left: 24px;
    height: 1rem;
    color: #666;
}

.metadata-config-edit-row-left {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
}

.metadata-edit-select {
    width: 190px;
    margin-right: 1rem;
    margin-top: 12px;
}

.metadata-traits-edit-inner .metadata-trait-remove-button {
    margin: 0;
    margin-left: 36px;
}

.metadata-config-edit-row .metadata-trait-remove-outer-button {
    margin-left: 36px;
}

.metadata-traits-edit-form .metadata-edit-input {
    margin-top: 32px;
    background-color: rgba(0, 0, 0, 0.04);
}

.metadata-traits-edit-form .metadata-edit-input input {
    padding: 20px 12px 6px 12px;
}

.metadata-traits-edit-form .metadata-edit-input label {
    margin-left: 12px;
    margin-top: 12px;
}

/* footer */
.metadata-edit-footer {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

.metadata-edit-footer button {
    margin: 0 8px;
}

/*  */
.pre-selection-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pre-selection-container h2 {
    color: #666;
    margin-top: 56px;
    margin-bottom: 96px;
    font-size: 24px;
}

.pre-selection-container .pre-selection-button,
.upload-container .upload-button {
    width: 300px;
    margin-top: 36px;
    padding: 12px 8px;
}

/* uploader */
.uploader-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 212px;
}

.upload-container {
    margin-top: 36px;
}

/* predefined */
.predefined-input-array {
    display: flex;
    flex-wrap: wrap;
}

/* random */
.metadata-config-edit-random {
    margin-top: 24px;
}

.metadata-config-edit-random-row {
    display: flex;
    flex-wrap: wrap;
}

/* distribution details */
.distribution-value-row {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
}
