/* #list-actions-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
} */

/* list table row padding reset */
.nft-list .MuiTableCell-sizeSmall {
    padding: 4px 24px 4px 16px;
}

/* metadata block */
.metadata-block {
    display: flex;
}

.metadata-item {
    min-width: 164px;
    margin-bottom: 2px;
    display: flex;
}

.metadata-item .name {
    font-size: 12px;
    margin: 0;
    margin-right: 4px;
    color: #999;
}

.metadata-item .view-all {
    margin-left: 16px;
}

.metadata-item .value {
    font-size: 12px;
    margin: 0;
    color: #333;
}

.metadata-block-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
}

.metadata-block-middle {
    display: flex;
    margin-right: 16px;
    margin-left: 8px;
}

.metadata-block-middle img {
    width: 64px;
    height: 64px;
    align-self: center;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.metadata-block-middle .image-edit-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .85);
    border: 1px solid #ccc;
}

.metadata-block-middle:hover .image-edit-button {
    display: block;
}

.metadata-tooltip-image {
    width: 300px;
    height: 300px;
    background-color: #aaa;
    border: 1px solid #999;
    border-radius: 4px;
}

.metadata-block-middle .empty-image {
    display: flex;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #acacac;
    text-align: center;
    width: 64px;
    height: 64px;
    color: #eee;
    font-weight: 700;
}

.metadata-block-right {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.metadata-traits-detail {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
}

/* trait item */
.trait-item {
    display: flex;
    border-radius: 4px;
    border: 1px solid rgb(21, 178, 229);
    color: #fff;
    margin: 2px 4px 2px 0;
    height: 18px;    
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    background: rgba(21, 178, 229, 0.06);
}

.trait-item .type {
    padding: 0px 4px;
    background-color: #3f51b5;
    margin-right: 4px;
    min-width: 64px;
    text-align: center;
}

.trait-item .value {
    color: #333;
    margin-right: 4px;
    padding: 0 2px;
    flex: 1;
}

.trait-item .per {
    color: #3f51b5;
    padding: 0 2px;
}

/* operations */
.operations {

}

.operations .operation-button {
    margin-right: 12px;
}

.suboperation-wrapper {
    padding: 4px 12px;
}

.suboperation-wrapper button,
.suboperation-wrapper .redirect-button {
    width: 100%;
    margin: 8px 0;
}

.suboperation-download-button-group {
    display: flex;
}

.suboperation-left-button,
.suboperation-right-button {
    flex: 1;
}

.suboperation-right-button {
    margin-left: 8px;
}

/*  */
.confirm-desc-row {
    border-bottom: 1px solid #ccc;
}

.confirm-desc-title {
    margin-top: 8px;
    font-size: 12px;
}

.confirm-desc-title-address {
    margin-right: 8px;
    font-size: 16px;
}

.confirm-desc-ids {
    margin-top: 4px;
    margin-bottom: 8px;
}

.confirm-desc-id-chip {
    margin: 4px;
    margin-left: 0;
}

/* distribution */
.distribution-value-row {
    border-bottom: 1px solid #ccc;
}

.distribution-type-total {
    margin-top: -12px;
    margin-bottom: 12px;
}

.distribution-value-item {
    padding-bottom: 12px;
    margin-right: 12px;
}

.distribution-value-row .distribution-value-item .distribution-value-chip-avatar {
    border-radius: 16px;
    width: auto;
    padding: 0 8px;
}

.distribution-value-value {
    font-weight: 700;
}

.distribution-value-per {
    color: #333;
    margin-left: 8px;
    font-size: 12px;
}
